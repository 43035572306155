import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(auth)/create-account": [~7,[3]],
		"/(auth)/login": [~8,[3]],
		"/(auth)/logout": [9,[3]],
		"/(auth)/password-reset": [~10,[3]],
		"/(auth)/password-reset/[token]": [~11,[3]],
		"/(app)/stash": [~5,[2]],
		"/(app)/threads": [~6,[2]],
		"/(auth)/verify-email": [~12,[3]],
		"/(auth)/verify-email/[token]": [~13,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';