import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

Sentry.init({
  dsn: env.PUBLIC_SENTRY_DSN,
  environment: env.PUBLIC_STAGE,
  tracesSampleRate: Number(env.PUBLIC_SENTRY_TRACES_SAMPLE_RATE),
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

export const handleError = Sentry.handleErrorWithSentry();
